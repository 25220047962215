import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { CFormInput } from '@coreui/react'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  TextareaAutosize,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { CKEditor } from 'ckeditor4-react'
import { DateCalendar } from '@mui/x-date-pickers'
import { startOfDay } from 'date-fns'
import dayjs from 'dayjs'

export const FromToDate = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid item xs={12} sm={6}>
        <DatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/YYYY"
          margin="normal"
          id={props.fromDateKey}
          name={props.id}
          label={props.fromDateLabel}
          value={props.fromDateValue}
          style={{ width: '100%' }}
          onChange={(val) => {
            props.onChange({ target: { id: props.fromDateKey, value: dayjs(new Date(val)) } })
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/YYYY"
          margin="normal"
          id={props.toDateKey}
          name={props.id}
          label={props.toDateLabel}
          value={props.toDateValue}
          style={{ width: '100%' }}
          onChange={(val) => {
            props.onChange({ target: { id: props.toDateKey, value: dayjs(new Date(val)) } })
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </LocalizationProvider>
  )
}

export const CustomDate = (props) => {
  return (
    // <FormControl fullWidth={true}>
    <FormControl fullWidth={true} style={{ marginTop: 10, marginBottom: 10 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disableToolbar
          variant="inline"
          format={props.format ? props.format : 'DD/MM/YYYY'}
          margin="normal"
          // defaultValue
          id={props.id}
          name={props.id}
          label={props.label}
          value={props.value}
          defaultValue={dayjs(new Date())}
          style={{ width: '100%', margin: 0 }}
          onChange={(val) => {
            props.onChange({ target: { id: props.id, value: val } })
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disabled={props.disabled ? true : false}
        />
      </LocalizationProvider>
    </FormControl>
  )
}

export const CustomYear = (props) => {
  return (
    // <FormControl fullWidth={true}>
    <FormControl fullWidth={true} style={{ marginTop: 10, marginBottom: 10 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disableToolbar
          variant="inline"
          format={props.format ? props.format : 'YYYY'} // Set format to show only year
          margin="normal"
          id={props.id}
          name={props.id}
          label={props.label}
          value={props.value}
          defaultValue={dayjs(new Date()).startOf('year')} // Set default to the current year
          style={{ width: '100%', margin: 0 }}
          onChange={(val) => {
            props.onChange({ target: { id: props.id, value: val } })
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disabled={props.disabled ? true : false}
          views={['year']} // Restrict to year view only
        />
      </LocalizationProvider>
    </FormControl>
  )
}

export const CustomDateTime = (props) => {
  return (
    <FormControl fullWidth={true}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          variant="inline"
          format="DD/MM/YYYY HH:mm"
          margin="normal"
          id={props.id}
          label={props.label}
          value={props.value}
          style={{ width: '100%', margin: 0 }}
          onChange={(val) => {
            props.onChange({ target: { id: props.id, value: val } })
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disabled={props.disabled ? true : false}
          minDate={props.minDate ? props.minDate : undefined}
          maxDate={props.maxDate ? props.maxDate : undefined}
          inputProps={{ readOnly: true }}
          ampm={false}
        />
      </LocalizationProvider>
    </FormControl>
  )
}

export const CustomDateCalendar = (props) => {
  const [values, setValues] = React.useState([startOfDay(new Date())])
  const [value, setValue] = React.useState()
  return (
    <FormControl fullWidth={true}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          onChange={(newValue) => {
            // console.log(newValue)
            setValue(newValue)
          }}
          // value={values}
        />
      </LocalizationProvider>
    </FormControl>
  )
}

export const CustomCheckbox = (props) => {
  return (
    <FormControl>
      {props.label}
      <Checkbox
        checked={props.checked}
        id={props.id}
        name={props.id}
        tabIndex={-1}
        onClick={props.onClick}
        disabled={props.disabled === true ? true : false}
      />
    </FormControl>
  )
}

export const InLineCustomCheckbox = (props) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            id={props.id}
            name={props.id}
            onClick={props.onClick}
            disabled={props.disabled === true ? true : false}
          />
        }
        label={props.label}
      />
    </FormGroup>
  )
}

export const CustomInput = (props) => {
  return (
    <FormControl fullWidth={true} style={{ marginTop: 10, marginBottom: 10 }}>
      <InputLabel>{props.fieldLabel}</InputLabel>
      <Input
        id={props.id}
        name={props.id}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        type={props.type ? props.type : 'text'}
        disabled={props.disabled ? true : false}
        placeholder={props.placeholder ? props.placeholder : ''}
        {...props}
        onWheel={(e) => {
          e.target.blur()
        }}
      />
    </FormControl>
  )
}

export const CustomTextArea = (props) => {
  return (
    <FormControl fullWidth={true}>
      <label htmlFor={props.id}>{props.fieldLabel}</label>
      {/* <InputLabel>{props.fieldLabel}</InputLabel> */}
      <TextareaAutosize
        id={props.id}
        name={props.id}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled ? true : false}
        maxRows={4}
        minRows={2}
      />
    </FormControl>
  )
}

export const CustomFileInput = (props) => {
  return (
    <FormControl fullWidth={true}>
      <CFormInput
        type="file"
        id={props.id}
        name={props.id}
        onChange={props.onChange}
        disabled={props.disabled ? true : false}
      />
    </FormControl>
  )
}

export const CustomToggle = (props) => {
  return (
    <ToggleButtonGroup value={props.value} exclusive size="small" onChange={props.onChange}>
      {props.options.map((v, index) => {
        return (
          <ToggleButton
            key={index}
            value={v.id}
            size="small"
            disabled={v.id == props.value ? true : false}
          >
            {v.label}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}

export const CustomRadio = (props) => {
  return (
    <>
      <FormLabel component="legend">{props.label}</FormLabel>
      <RadioGroup
        row
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
      >
        {props.options.map((v, index) => {
          return (
            <FormControlLabel key={index} value={v.value} control={<Radio />} label={v.label} />
          )
        })}
      </RadioGroup>
    </>
  )
}

export const CustomTimePicker = (props) => {
  return (
    <TextField
      style={{ width: '100%' }}
      id={props.id}
      name={props.name}
      label={props.fieldLabel}
      type="time"
      value={props.value}
      onChange={props.onChange}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
      }}
    />
  )
}

export const CustomCKeditor = (props) => {
  return (
    <FormControl fullWidth={true}>
      <label>{props.fieldLabel}</label>
      <CKEditor
        id={props.id}
        config={{
          height: props.height,
          extraPlugins: 'justify,font,colorbutton',
          toolbarGroups: [
            { name: 'document', groups: ['mode', 'document', 'doctools'] },
            { name: 'clipboard', groups: ['clipboard', 'undo'] },
            { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
            { name: 'forms' },
            '/',
            { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
            {
              name: 'paragraph',
              groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
            },
            { name: 'links' },
            { name: 'insert' },
            '/',
            { name: 'styles' }, // 'font and fontsize' -> 'font' plugin
            { name: 'colors' }, // 'colors' -> 'colorbutton' plugin
            { name: 'tools' },
            { name: 'others' },
            { name: 'about' },
          ],
        }}
        initData={props.initData}
        onChange={props.onChange}
      />
    </FormControl>
  )
}

CustomRadio.propTypes = {
  label: PropTypes.node,
  defaultValue: PropTypes.node,
  value: PropTypes.node,
  onChange: PropTypes.func,
  name: PropTypes.node,
  options: PropTypes.array,
}

CustomToggle.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
}

FromToDate.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fromDateKey: PropTypes.node,
    fromDateLabel: PropTypes.node,
    fromDateValue: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired

CustomDate.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired

CustomYear.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired

CustomDateTime.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
    minDate: PropTypes.any,
  }).isRequired,
}).isRequired

CustomDateCalendar.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
  }).isRequired,
}).isRequired

CustomCheckbox.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    label: PropTypes.node,
    checked: PropTypes.node,
    onClick: PropTypes.node,
  }).isRequired,
}).isRequired

InLineCustomCheckbox.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    label: PropTypes.node,
    checked: PropTypes.node,
    onClick: PropTypes.node,
    disabled: PropTypes.node,
  }).isRequired,
}).isRequired

CustomInput.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fieldLabel: PropTypes.node,
    id: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired

CustomTextArea.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fieldLabel: PropTypes.node,
    id: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired

CustomFileInput.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fieldLabel: PropTypes.node,
    id: PropTypes.node,
    onChange: PropTypes.func,
    disabled: PropTypes.any,
  }).isRequired,
}).isRequired

CustomTimePicker.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fieldLabel: PropTypes.node,
    id: PropTypes.node,
    name: PropTypes.node,
    onChange: PropTypes.func,
    value: PropTypes.func,
  }).isRequired,
}).isRequired

CustomCKeditor.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    fieldLabel: PropTypes.node,
    id: PropTypes.node,
    initData: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired
