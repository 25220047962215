import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import PropTypes from 'prop-types'

const SingleCustomAutocomplete = (props) => {
  const filedName = props.filedName
  const fieldLabel = props.label
  const list = props.list
  const fieldValue = props.value
  const listKey = props.listKey
  const listDescription = props.description
  const customOnChange = props.customOnChange

  const optionForLabel = (label) => {
    if (typeof label === 'object' && !Array.isArray(label) && label !== null) {
      return label[listDescription]
    }
    let toReturn = list.find((source) => {
      return source[listKey] == label
    })
    if (toReturn) {
      return toReturn[listDescription]
    }
    return `${label}`
  }

  const getOptionSelected = (option, value) => {
    if (typeof option === 'object' && !Array.isArray(option) && option !== null) {
      return option[listKey] == value
    }
    return false
  }

  const update = (event, value) => {
    if (value === null) {
      customOnChange(null, filedName, '', event, value)
      return
      // }else if( typeof value === 'string'){
      //     setValue(optionForLabel(value))
    } else {
      customOnChange(value[listKey], filedName, value[listDescription], event, value)
    }
  }

  return (
    // <FormControl style={{ width: '100%' }}>
    <FormControl fullWidth={true} style={{ marginTop: 10, marginBottom: 10 }}>
      <Autocomplete
        // isOptionEqualToValue={(option, value) => option.id === value}
        disabled={props.disabled ? props.disabled : false}
        name={filedName}
        options={list} // Options List
        value={fieldValue}
        onChange={update}
        getOptionLabel={optionForLabel}
        // getOptionSelected={getOptionSelected}
        style={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} label={fieldLabel} />}
      />
    </FormControl>
  )
}

SingleCustomAutocomplete.propTypes = {
  filedName: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  list: PropTypes.any.isRequired,
  value: PropTypes.any,
  listKey: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  customOnChange: PropTypes.any.isRequired,
  disabled: PropTypes.any,
}

export default SingleCustomAutocomplete
