import React, { useEffect, useState } from 'react'
import { CSpinner } from '@coreui/react'
import axios from 'axios'
import cookie from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import allActions from './actions/allActions'
import './scss/style.scss'
import './i18n.js'
import { useTranslation } from 'react-i18next'
import Dashboard from './views/dashboard/Dashboard'
import Reports from './views/reports/Reports'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

const App = () => {
  const { t, i18n } = useTranslation()
  const [language, setlanguage] = useState(i18n.language)

  const token = cookie.get('token')
  const currentUser = useSelector((state) => state.currentUser)
  const dispatch = useDispatch()
  const [requestedUrl, setRequestedUrl] = useState('')

  useEffect(() => {
    const vaildateUser = async () => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/auth/admin/profile`)
        .then((res) => {
          dispatch(allActions.userActions.setUser(res.data.user))
          localStorage.setItem('profile_forms', JSON.stringify(res.data.profile_forms))
          localStorage.setItem('profile_forms_codes', JSON.stringify(res.data.profile_forms_codes))

          axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/auth/admin/privileges`)
            .then((res) => {
              dispatch(allActions.userActions.setUserPrivileges(res.data))
            })
            .catch((error) => console.log(error.response))
        })
        .catch((err) => {
          axios.defaults.headers.common['Authorization'] = ``
          localStorage.clear()
          cookie.remove('token')
          dispatch(allActions.userActions.logOut())
        })
    }
    if (token) {
      vaildateUser()
    } else {
      localStorage.clear()
    }
  }, [token])
  useEffect(() => {
    if (!requestedUrl) {
      setRequestedUrl(window.location.hash.substring(1))
    }
  }, [])

  const Loader = () => {
    return (
      <div className={'d-flex justify-content-center align-items-center h-100'}>
        <CSpinner color="primary" />
      </div>
    )
  }

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <ToastContainer />

        <Routes>
          <Route
            path="*"
            name="Main"
            element={
              currentUser?.loggedIn === true ? <DefaultLayout /> : token ? <Loader /> : <Login />
            }
          />

          <Route path="/statistiques" element={<Reports />} />

          <Route path="/404" element={<Page404 />} />
        </Routes>
      </React.Suspense>
    </HashRouter>
  )
}

export default App
