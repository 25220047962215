import React, { useEffect, useState } from 'react'

import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale,
} from '@coreui/icons'

import avatar1 from 'src/assets/images/avatars/1.jpg'
import avatar2 from 'src/assets/images/avatars/2.jpg'
import avatar3 from 'src/assets/images/avatars/3.jpg'
import avatar4 from 'src/assets/images/avatars/4.jpg'
import avatar5 from 'src/assets/images/avatars/5.jpg'
import avatar6 from 'src/assets/images/avatars/6.jpg'

import WidgetsBrand from '../widgets/WidgetsBrand'
import WidgetsDropdown from '../widgets/WidgetsDropdown'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import img1 from '../../assets/images/items.png'
import CustomCalendar from 'src/components/Inputs/CustomCalendar'
import logo from 'src/assets/brand/logo.png'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
const Dashboard = () => {
  const { t, i18n } = useTranslation()

  const [data, setData] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const getData = () => {
    setIsLoading(true)
    axios(`${process.env.REACT_APP_API_BASE_URL}/masterData/admin_users/content/get`, {
      responseType: 'json',
    })
      .then(function (response) {
        setData(response.data)
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Grid
      container
      style={{
        overflowY: 'scroll',
        height: 'calc(100vh - 67px)',
        paddingBottom: 100,
        backgroundColor: 'white',
      }}
    >
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ margin: 'auto', marginBottom: 50 }}
      >
        <img width={200} style={{ objectFit: 'contain', marginRight: '20px' }} src={logo} />
        <h1>Centre de Prévention-Accompagnement-Développement</h1>
      </div>

      {data.map((category, index) => (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          key={index}
          style={{ padding: 20 }}
          container
          flexDirection={'column'}
          alignContent={'center'}
        >
          <div className="d-flex align-items-center">
            <img
              width={120}
              style={{ objectFit: 'contain', marginRight: '20px' }}
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/${category.image}`}
            />
            <h2>{category.title}</h2>
          </div>

          {category.content.map((content, index) => (
            <div key={index} style={{ paddingInline: 20, marginLeft: 120 }}>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/${content.attachment}`}
              >
                <b>{content.title}</b>
                {/* {t('link')} */}
              </a>
              <p>{content.description}</p>
            </div>
          ))}
        </Grid>
      ))}
    </Grid>
  )
}

export default Dashboard
