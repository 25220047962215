import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'
import Box from '@mui/material/Box'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { CCard, CCardBody, CRow, CCol } from '@coreui/react'
import { useSelector } from 'react-redux'
import SingleCustomAutocomplete from 'src/components/CustomAutocomplete/SingleCustomAutocomplete'
import { CustomYear } from 'src/components/Inputs/CustomInputForms'
const Reports = () => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

  const { t, i18n } = useTranslation()

  const [data, setData] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const [formData, setFormData] = useState({
    branch_id: 3,
    year: null,
  })
  const customOnChange = (newValue, name) => {
    setFormData({
      ...formData,
      [name]: newValue,
    })
  }
  const handleInputChange = (e) => {
    const { id, name, value, checked, type } = e.target
    setFormData({
      ...formData,
      [id]: value,
    })
  }

  const status_data = {
    labels: data['status']?.labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: data['status']?.data,
        backgroundColor: '#81B1D5',
      },
    ],
  }
  const stages_data = {
    labels: data['stages']?.labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: data['stages']?.data,
        backgroundColor: 'orange',
      },
    ],
  }
  const ages_data = {
    labels: data['ages'] ? Object.keys(data['ages']) : [],
    datasets: [
      {
        // label: 'Dataset 1',
        data: data['ages'] ? Object.values(data['ages']) : [],
        backgroundColor: [
          'blue',
          'blue',
          'yellow',
          'yellow',
          'green',
          'green',
          'red',
          'red',
          'red',
          'red',
        ],
      },
    ],
  }

  const doctors_data = {
    labels: data['doctors']?.labels,
    datasets: [
      {
        label: 'Total',
        data: data['doctors']?.total,
        backgroundColor: 'blue',
      },

      {
        label: 'M',
        data: data['doctors']?.M,
        backgroundColor: 'red',
      },
      {
        label: 'F',
        data: data['doctors']?.F,
        backgroundColor: 'grey',
      },

      {
        label: 'En cours',
        data: data['doctors']?.status1,
        backgroundColor: 'orange',
      },
      {
        label: 'Suspension',
        data: data['doctors']?.status2,
        backgroundColor: 'lightblue',
      },
      {
        label: 'Abandon',
        data: data['doctors']?.status3,
        backgroundColor: 'green',
      },
      {
        label: 'Clôture',
        data: data['doctors']?.status4,
        backgroundColor: 'black',
      },
    ],
  }

  const gender_ages_data = {
    labels: data['ages'] ? Object.keys(data['ages']) : [],
    datasets: [
      {
        label: 'Male',

        data: data['male_ages'] ? Object.values(data['male_ages']) : [],
        backgroundColor: 'blue',
      },

      {
        label: 'Female',

        data: data['female_ages'] ? Object.values(data['female_ages']) : [],
        backgroundColor: 'red',
      },
    ],
  }

  const gender_orientations = {
    labels: data['male_orientations']?.labels,
    datasets: [
      {
        label: 'Male',
        data: data['male_orientations']?.data,
        data: data['male_orientations']?.data,
        backgroundColor: 'blue',
      },

      {
        label: 'Female',

        data: data['female_orientations']?.data,
        backgroundColor: 'red',
      },
    ],
  }

  const gender_data = {
    labels: data['gender'] ? Object.keys(data['gender']) : [],
    datasets: [
      {
        // label: 'Dataset 1',
        data: data['gender'] ? Object.values(data['gender']) : [],
        backgroundColor: ['blue', 'orange', 'green'],
      },
    ],
  }
  const family_data = {
    labels: data['family'] ? Object.keys(data['family']) : [],
    datasets: [
      {
        data: data['family'] ? Object.values(data['family']) : [],
        backgroundColor: ['blue', 'orange', 'green', 'yellow', 'brown', 'grey'],
        borderColor: ['blue', 'orange', 'green', 'yellow', 'brown', 'grey'],
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.label
              let value = context.formattedValue

              if (!label) label = 'Unknown'

              let sum = 0
              let dataArr = context.chart.data.datasets[0].data
              dataArr.map((data) => {
                sum += Number(data)
              })

              let percentage = ((value * 100) / sum).toFixed(0) + '%'
              return label + ': ' + percentage
            },
          },
        },
      },
    ],
  }

  const orientations_data = {
    labels: data['orientations']?.labels,
    datasets: [
      {
        label: 'Nature des suivis.',
        data: data['orientations']?.data,
        backgroundColor: [
          '#0074D9',
          '#FF4136',
          '#2ECC40',
          '#FF851B',
          '#7FDBFF',
          '#B10DC9',
          '#FFDC00',
          '#001f3f',
          '#39CCCC',
          '#01FF70',
          '#85144b',
          '#F012BE',
          '#3D9970',
          '#111111',
          '#AAAAAA',
        ],
        borderColor: [
          '#0074D9',
          '#FF4136',
          '#2ECC40',
          '#FF851B',
          '#7FDBFF',
          '#B10DC9',
          '#FFDC00',
          '#001f3f',
          '#39CCCC',
          '#01FF70',
          '#85144b',
          '#F012BE',
          '#3D9970',
          '#111111',
          '#AAAAAA',
        ],
        borderWidth: 1,
      },
    ],
  }

  const schools_data = {
    labels: data['schools']?.labels,
    datasets: [
      {
        label: 'Services aux écoles',
        data: data['schools']?.data,
        backgroundColor: ['orange', 'grey', 'brown', 'yellow', 'green', 'red', 'blue'],
        borderColor: ['orange', 'grey', 'brown', 'yellow', 'green', 'red', 'blue'],
        borderWidth: 1,
      },
    ],
  }

  const diagnostics_data = {
    labels: data['diagnostics']?.labels,
    datasets: [
      {
        label: 'Nature des suivis.',
        data: data['diagnostics']?.data,
        backgroundColor: ['blue', 'orange', 'green', 'yellow', 'brown', 'grey'],
        borderColor: ['blue', 'orange', 'green', 'yellow', 'brown', 'grey'],
        borderWidth: 1,
      },
    ],
  }

  const psychology_data = {
    labels: data['psychology']?.labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: data['psychology']?.data,
        backgroundColor: 'blue',
      },
    ],
  }
  const psychomotricity_data = {
    labels: data['psychomotricity']?.labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: data['psychomotricity']?.data,
        backgroundColor: 'blue',
      },
    ],
  }

  const orthophony_data = {
    labels: data['orthophony']?.labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: data['orthophony']?.data,
        backgroundColor: 'blue',
      },
    ],
  }
  const orthopedagogy_data = {
    labels: data['orthopedagogy']?.labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: data['orthopedagogy']?.data,
        backgroundColor: 'blue',
      },
    ],
  }
  const regions_data = {
    labels: data['regions']?.labels,
    datasets: [
      {
        data: data['regions']?.data,
        backgroundColor: '#81B1D5',
      },
    ],
  }

  const getData = () => {
    setIsLoading(true)
    axios(`${process.env.REACT_APP_API_BASE_URL}/masterData/admin_users/reports/get`, {
      responseType: 'json',
      params: { branch_id: formData.branch_id, year: formData.year },
    })
      .then(function (response) {
        setData(response.data)
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [formData.branch_id, formData.year])

  return (
    <>
      <CRow className="m-2">
        <CCol md={6}>
          <SingleCustomAutocomplete
            filedName="branch_id"
            label={t('branch')}
            list={[
              { id: 1, name: 'Ghadir' },
              { id: 2, name: 'Ghbaleh' },
              { id: 4, name: 'École Inclusive' },
              { id: 3, name: 'Les 3  branches' },
            ]}
            value={formData.branch_id}
            listKey="id"
            description="name"
            customOnChange={customOnChange}
          />
        </CCol>

        <CCol md={6}>
          <CustomYear
            id="year"
            label={'Annee'}
            value={formData.year}
            onChange={handleInputChange}
          />
        </CCol>
      </CRow>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          minWidth: 300,
          // width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          overflowY: 'scroll',
          paddingBottom: '100px',
        }}
      >
        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            indexAxis: 'y',
            elements: {
              bar: {
                borderWidth: 2,
              },
            },
            responsive: true,

            barThickness: 50,
            maxBarThickness: 50,

            plugins: {
              legend: {
                display: false,
                position: 'right',
              },
              title: {
                display: true,
                text: 'Synthèse des suivis',
              },
            },
          }}
          data={status_data}
        />

        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            responsive: true,

            barThickness: 50,
            maxBarThickness: 50,

            scales: {
              y: {
                min: 0,
                max: data['patients']?.total,
                stepSize: 1,
              },
              x: {},
            },
            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: true,
                text: '',
              },
            },
          }}
          data={stages_data}
        />

        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            responsive: true,

            barThickness: 50,
            maxBarThickness: 50,

            scales: {
              y: {
                min: 0,
                max: data['patients']?.total,
                stepSize: 1,
              },
              x: {},
            },

            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: true,
                text: 'Répartition par âge',
              },
            },
          }}
          data={ages_data}
        />

        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            responsive: true,

            barThickness: 25,
            maxBarThickness: 25,

            scales: {
              y: {
                min: 0,
                max: data['patients']?.total,
                stepSize: 1,
              },
              x: {},
            },

            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: true,
                text: 'Catégories par âge et sexe',
              },
            },
          }}
          data={gender_ages_data}
        />

        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            responsive: true,

            barThickness: 50,
            maxBarThickness: 50,

            scales: {
              y: {
                min: 0,
                max: data['gender']?.total,
                stepSize: 1,
              },
              x: {},
            },

            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: true,
                text: 'Catégories par sexes des bénéficiaire',
              },
            },
          }}
          data={gender_data}
        />

        <Pie style={{ maxHeight: 600, maxWidth: 600, margin: 50 }} data={orientations_data} />
        {/* <Pie style={{ maxHeight: 600, maxWidth: 600, margin: 50 }} data={diagnostics_data} /> */}

        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            responsive: true,

            barThickness: 25,
            maxBarThickness: 25,

            scales: {
              y: {
                min: 0,
                max: data['patients']?.total,
                stepSize: 1,
              },
              x: {},
            },

            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: true,
                text: 'Suivis thérapeutique par sexe',
              },
            },
          }}
          data={gender_orientations}
        />

        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            responsive: true,

            barThickness: 50,
            maxBarThickness: 50,
            scales: {
              y: {
                min: 0,
                max: data['patients']?.total,
                stepSize: 1,
              },
              x: {},
            },
            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: true,
                text: 'Suivi en Psychologie',
              },
            },
          }}
          data={psychology_data}
        />
        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            responsive: true,

            barThickness: 50,
            maxBarThickness: 50,
            scales: {
              y: {
                min: 0,
                max: data['patients']?.total,
                stepSize: 1,
              },
              x: {},
            },
            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: true,
                text: 'Suivi en Psychomotricité',
              },
            },
          }}
          data={psychomotricity_data}
        />

        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            responsive: true,

            barThickness: 50,
            maxBarThickness: 50,
            scales: {
              y: {
                min: 0,
                max: data['patients']?.total,
                stepSize: 1,
              },
              x: {},
            },
            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: true,
                text: 'Diagnostics en Orthophonie',
              },
            },
          }}
          data={orthophony_data}
        />
        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            responsive: true,

            barThickness: 50,
            maxBarThickness: 50,
            scales: {
              y: {
                min: 0,
                max: data['patients']?.total,
                stepSize: 1,
              },
              x: {},
            },
            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: true,
                text: 'Diagnostics en Orthopédagogie',
              },
            },
          }}
          data={orthopedagogy_data}
        />
        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            responsive: true,

            barThickness: 50,
            maxBarThickness: 50,

            scales: {
              y: {
                min: 0,
                max: data['patients']?.total,
                stepSize: 1,
              },
              x: {},
            },

            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: true,
                text: 'Service dans les régions',
              },
            },
          }}
          data={regions_data}
        />

        <Bar
          style={{ maxHeight: 600, maxWidth: 600, margin: 50 }}
          options={{
            responsive: true,

            barThickness: 20,
            maxBarThickness: 20,

            scales: {
              y: {
                min: 0,
                max: data['patients']?.total,
                stepSize: 1,
              },
              x: {},
            },

            plugins: {
              legend: {
                display: true,
                position: 'top',
              },
              title: {
                display: true,
                text: 'Bénéficiaires suivis par professionnel',
              },
            },
          }}
          data={doctors_data}
        />

        <Pie style={{ maxHeight: 600, maxWidth: 600, margin: 50 }} data={schools_data} />

        <Pie style={{ maxHeight: 600, maxWidth: 600, margin: 50 }} data={family_data} />
      </Box>
    </>
  )
}

export default Reports
